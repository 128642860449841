@-ms-viewport {
	width: device-width;
	initial-scale: 1;
}

html {
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

body {
	margin: 0;
}

body,
body * {
	box-sizing: border-box;
}

/* HTML5 display definitions
   ========================================================================== */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
	display: block;
}

audio,
canvas,
progress,
video {
	vertical-align: baseline;
	display: inline-block;
}

audio:not([controls]) {
	height: 0;
	display: none;
}

[hidden],
template {
	display: none;
}

/* Links
   ========================================================================== */
a {
	color: inherit;
	text-decoration: none;
	background-color: transparent;
	outline: 0;
}

/* Text-level semantics
   ========================================================================== */
abbr[title] {
	border-bottom: dotted 1px;
}

b,
strong {
	font-weight: 600;
}

em,
dfn {
	font-style: italic;
}

h1, h2, h3,
h4, h5, h6 {
	margin: 0;
	font-size: inherit;
	font-weight: 600;
	line-height: 1.3;
}

p {
	margin: 0;
}

dl, ol, ul,
dt, dd, li {
	margin: 0;
	padding: 0;
	list-style: none;
	list-style-image: none;
}

mark {
	color: #000;
	background-color: #ff0;
}

small {
	font-size: 80%;
}

sup,
sub {
	font-size: 75%;
	line-height: 0;
	vertical-align: baseline;
	position: relative;
}

sup {
	top: -0.5em;
}

sub {
	bottom: -0.25em;
}

/* Embedded content
   ========================================================================== */
img {
	max-width: 100%;
	border: 0;
}

img[src$=".svg"] {
	height: 100%;
}

svg:not(:root) {
	overflow: hidden;
}

/* Grouping content
   ========================================================================== */
figure {
	margin: 0;
}

hr {
	height: 0;
	box-sizing: content-box;
}

pre {
	overflow: auto;
}

code,
kbd,
pre,
samp {
	font-family: monospace, monospace;
	font-size: inherit;
}

/* Forms
   ========================================================================== */
button,
input,
optgroup,
select,
textarea {
	margin: 0;
	color: inherit;
	font: inherit;
}

button {
	overflow: visible;
}

button,
select {
	text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
	cursor: pointer;
	-webkit-appearance: button;
}

button[disabled],
html input[disabled] {
	cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
	padding: 0;
	border: 0;
}

input {
	line-height: normal;
}

input[type="checkbox"], input[type="radio"] {
	padding: 0;
	box-sizing: border-box;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	height: auto;
}

input[type="search"] {
	box-sizing: content-box;
	-webkit-appearance: textfield;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

fieldset {
	margin: 0 2px;
	padding: 0.35em 0.625em 0.75em;
	border: solid 1px #c0c0c0;
}

legend {
	padding: 0;
	border: 0;
}

textarea {
	overflow: auto;
}

optgroup {
	font-weight: 600;
}

/* Tables
   ========================================================================== */
table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
}

td,
th {
	padding: 0;
}
